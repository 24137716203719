import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import HelmetComponent from '../../components/Helmet/Helmet'
import classes from "./SingleAnnouncement.module.css"
import { Button, Col, Row, Collapse, Select } from 'antd';
import { SampleNextArrow, SamplePrevArrow } from '../../common/ArrowsForCarousel'
import { SearchOutlined, CheckOutlined, SettingOutlined } from '@ant-design/icons'
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import facebook from '../../img/facebook.png'
import twitter from '../../img/twitter.png'
import linkedIn from '../../img/linkedin.png'
import announcementImg from '../../img/Announcement1.png'
import { log } from "console";

const SingleAnnouncement = () => {
    const { t } = useTranslation()
    const { recid } = useParams();

    const [size, setSize] = useState<SizeType>('large'); // default is 'middle'
    const [newsItem, setNewsItem] = useState<any>(null);

    // const { Panel } = Collapse;
    // const { Option } = Select;

//     const text1 = `
//   Περιεχόμενο 1
// `;
//     const text2 = `
//   Περιεχόμενο 2
// `;
//     const text3 = `
//   Περιεχόμενο 3
// `;
//     const text4 = `
//   Περιεχόμενο 4
// `;

    type ExpandIconPosition = 'start' | 'end';

    const [expandIconPosition, setExpandIconPosition] = useState<ExpandIconPosition>('end');

    const onPositionChange = (newExpandIconPosition: ExpandIconPosition) => {
        setExpandIconPosition(newExpandIconPosition);
    };

    const onChange = (key: string | string[]) => {
        console.log(key);
    };

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1056,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const formatDate = (dateStr: string) => {
        const date = new Date(dateStr);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString().slice(-2);

        return `${day}/${month}/${year}`;
    };

    useEffect(() => {
        // Function to fetch the specific news item based on recid and set it to state
        const fetchNewsItem = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_NAME}/api/News/GetByNewsID/${recid}`);
                const newsData = response.data;
                setNewsItem(newsData);

                window.scrollTo(0, 0);
            } catch (error) {
                console.error("Error fetching news item:", error);
            }
        };

        fetchNewsItem();
    }, [recid]);

    return (
        <>
            <HelmetComponent title={t('ΕΑΤΑ') || undefined} descrition={t('ΕΑΤΑ') || undefined} />
            <div className={classes.container}>
                <div className={classes.benefitsContainer}>
                    <div className={classes.benefits}>
                        <span className={classes.benefitsTitle}>{t('Νέα / Ανακοινώσεις')}</span>
                        <br /><br />

                        {newsItem ? (
                            <>
                                <div className={classes.benefit}>
                                    <div className={classes.imgContainer}>
                                        <img className={classes.imgNews} src={newsItem.image_url || announcementImg} alt="PostTitle" />
                                    </div>
                                    <div className={classes.TextContainer}>
                                        <span className={classes.Date}>{formatDate(newsItem.date_submitted)}</span>
                                        <br /><br />
                                        <span className={classes.TextTitle}>{newsItem.title}</span><br /><br />
                                        <br />
                                        {/* this breaks */}
                                        <span className={`${classes.Desc} ${classes.truncateText}`} dangerouslySetInnerHTML={{ __html: newsItem.content }}></span>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div>Παρακαλούμε περιμένετε...</div> // Display a loading message while fetching the news item
                        )}


                        {/* <div className={classes.imgContainer}>
                                <img src={announcementImg} alt="PostTitle" />
                            </div>
                            <div className={classes.TextContainer}>
                                <span className={classes.Date}>18/02/20</span>
                                <br /><br />
                                <span className={classes.TextTitle}>Ενημέρωση σχετικά με την τροποποίηση της Πρόσκλησης του Προγράμματος Ι </span><br /><br />
                                <br />
                                <span className={classes.Desc}>Ο κλάδος των ψηφιακών τεχνολογιών αποτελεί έναν από τους πλέον δυναμικούς κλάδους της Ελληνικής Οικονομίας. Ο κλάδος συμβάλει ευθέως στο 8% του ΑΕΠ της χώρας, οι ρυθμοί ανάπτυξης είναι μεταξύ των υψηλότερων στη χώρα (6,1% μεταξύ 2017 – 2021 σε όρους CAGR),  αρκετά ψηλότερα του αντίστοιχου μέσου όρου των χωρών του ΟΟΣΑ.
                                    <br /><br /><br /><br />
                                    Ειδικότερα:
                                    H Ευρωπαϊκή Επιτροπή αντιμετωπίζει τις ψηφιακές τεχνολογίες ως κομβικής σημασίας για την επίτευξη της Ευρωπαϊκής Αναπτυξιακής Στρατηγικής και την επίτευξη των στόχων βιωσιμότητας της Ευρώπης, επενδύοντας σημαντικά στην ενίσχυση των τεχνολογιών πληροφορικής και επικοινωνιών, μέσω Προγραμμάτων όπως το Horizon Europe, το Digital Europe, κ.ά., καθώς και μέσω της Ευρωπαϊκής Πράσινης Συμφωνίας.
                                    Σε αυτό το αναπτυξιακό πλαίσιο, η χώρα μας επιδεικνύει έλλειμμα ενσωμάτωσης των ψηφιακών τεχνολογιών στην Οικονομία και Κοινωνία, ενώ παράλληλα, ο κλάδος εμφανίζει ισχυρά περιθώρια περαιτέρω ανάπτυξης της εξωστρέφειάς του, καθώς, μέχρι σήμερα καλύπτει κατά κανόνα τις εγχώριες ανάγκες.

                                    Λαμβάνοντας υπόψη τις ανωτέρω αναπτυξιακές παραμέτρους, σκοπός του Προγράμματος «ΑΝΑΠΤΥΞΗ ΨΗΦΙΑΚΩΝ ΠΡΟΪΟΝΤΩΝ ΚΑΙ ΥΠΗΡΕΣΙΩΝ» είναι η ενίσχυση της ψηφιακής παραγωγικής ικανότητας στη χώρα με νέα προϊόντα και υπηρεσίες, και η ενίσχυση της εξωστρέφειας των Ελληνικών Επιχειρήσεων που δραστηριοποιούνται στην παραγωγή και διάθεση ψηφιακών προϊόντων και υπηρεσιών.
                                    Ειδικότερα:
                                    Το Πρόγραμμα «ΑΝΑΠΤΥΞΗ ΨΗΦΙΑΚΩΝ ΠΡΟΪΟΝΤΩΝ ΚΑΙ ΥΠΗΡΕΣΙΩΝ» ενισχύει επενδυτικά σχέδια για την ανάπτυξη νέων προϊόντων και υπηρεσιών που εμπίπτουν στον κλάδο πληροφορικής και επικοινωνιών, αποσκοπώντας:
                                    στην ενίσχυση της υγιούς επιχειρηματικότητας μέσω της αξιοποίησης των ΤΠΕ,
                                    στην ενδυνάμωση του κλάδου ΤΠΕ (σε όρους ανταγωνιστικότητας και εξωστρέφειας).
                                    στη δημιουργία νέων ψηφιακών προϊόντων και υπηρεσιών προστιθέμενης αξίας με ισχυρή εμπορική προοπτική και βιωσιμότητα.

                                </span>
                            </div> */}

                    </div>
                </div>
                <div className={classes.socialMedia}>
                    <img src={facebook} alt="Facebook icon" />
                    <img src={twitter} alt="Twitter icon" />
                    <img src={linkedIn} alt="LinkedIn icon" />
                </div>
            </div>
        </>
    )
}

export default SingleAnnouncement
