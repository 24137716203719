import { useTranslation } from "react-i18next";
import HelmetComponent from '../../components/Helmet/Helmet'
import classes from "./About.module.css"
import { Button, Col, Row, Collapse, Select, Form, Input, Space, SelectProps, Steps, notification } from 'antd';
import { SampleNextArrow, SamplePrevArrow } from '../../common/ArrowsForCarousel'
import { SearchOutlined, CheckOutlined, SettingOutlined, CloseOutlined } from '@ant-design/icons'
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import requestIcon from '../../img/request.png'
import teams from '../../img/Microsoft_Office_Teams.png'
import powerPoint from '../../img/Microsoft_Office_PowerPoint.png'
import sway from '../../img/Microsoft_Office_Sway.png'
import oneNote from '../../img/Microsoft_Office_OneNote.png'
import visio from '../../img/Microsoft_Office_Visio.png'
import sharePoint from '../../img/Microsoft_Office_SharePoint.png'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "../../components/Modal/Modal";
import useModal from "../../hooks/useModal";
import Spinner from "../../common/Spinner";
import danger from '../../img/danger.png'
import facebook from '../../img/facebook.png'
import twitter from '../../img/twitter.png'
import linkedIn from '../../img/linkedin.png'

const About = () => {
  const { t } = useTranslation()

  const [form] = Form.useForm();

  const [size, setSize] = useState<SizeType>('large'); // default is 'middle'

  const { Panel } = Collapse;
  const { Option } = Select;

  const { isOpenSearch, isOpenSearchResults, isOpenResults, isOpenFailed, toggleSearch, toggleSearchResults, toggleResults, toggleFailed } = useModal();
  const [current, setCurrent] = useState<number>(2);
  const [notification, setNotification] = useState<string>('')
  const [currentStatus, setCurrentStatus] = useState<string>('approved');

  const handleButtonClick = (step: number) => {
    switch (step) {
      case 1:
        toggleSearch();
        toggleSearchResults();
        break;
      case 2:
        toggleSearchResults();
        toggleResults();
        break;
      case 3:
        toggleResults();
        toggleFailed()
        break;
      default:
        break;
    }
  };

//   const text1 = `
//   Περιεχόμενο 1
// `;
//   const text2 = `
//   Περιεχόμενο 2
// `;
//   const text3 = `
//   Περιεχόμενο 3
// `;
//   const text4 = `
//   Περιεχόμενο 4
// `;
//   const text5 = `
//   Περιεχόμενο 5
// `;


  type ExpandIconPosition = 'start' | 'end';

  const [expandIconPosition, setExpandIconPosition] = useState<ExpandIconPosition>('end');

  const onPositionChange = (newExpandIconPosition: ExpandIconPosition) => {
    setExpandIconPosition(newExpandIconPosition);
  };
  const onFinish = (values: any) => {
    // alert(values["firstname"]);
    console.log(values);

    toggleSearch();
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1056,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const description = 'This is a description.';

  const items = [
    {
      title: 'Υποβολή',
      description: "04/02/23",
    },
    {
      title: 'Σε εξέλιξη',
      // description: "10/02/23",
    },
    {
      title: currentStatus == "rejected" ? 'Απορρίφθηκε' : currentStatus == "approved" ? 'Εγκρίθηκε' : 'Εγκρίθηκε / Απορρίφθηκε',
      description: currentStatus ? "10/02/23" : null,
      icon: currentStatus == "rejected" ? <div className={classes.closeOutlinedContainer}><CloseOutlined style={{ color: '#ED5350', width: '42px', fontSize: '21px' }} /></div> : null
    },
  ];

  const getCurrentStep = () => {
    if (!notification && current == 2 && currentStatus == "rejected") {
      console.log("status: ", currentStatus);
      setNotification('Λυπούμαστε, αλλά δεν πληρούνται τα βασικά κριτήρια συμμετοχής στο πρόγραμμα. Ευχαριστούμε που υποβάλατε την αίτησή σας.');
    }
    else if (!notification && current == 2 && currentStatus == "approved") {
      setNotification('Η αίτησή σας έχει εγκριθεί. Στα στοιχεία επικοινωνίας που έχετε δηλώσει, σας έχουν σταλεί οδηγίες για τη σύνδεση στο λογαριασμό σας. Σε περίπτωση που δεν έχετε λάβει σχετικό μήνυμα, παρακαλώ επικοινωνήστε μαζί μας.');
      console.log("status: ", currentStatus);
      setCurrent(current + 1)
      return (current + 1);
    }
    // else if (current == 3) {
    //   setNotification('Η αίτησή σας έχει εγκριθεί. Στα στοιχεία επικοινωνίας που έχετε δηλώσει, σας έχουν σταλεί οδηγίες για τη σύνδεση στο λογαριασμό σας. Σε περίπτωση που δεν έχετε λάβει σχετικό μήνυμα, παρακαλώ επικοινωνήστε μαζί μας.');
    // }
    return current;
  }

  const useEffect = () => {

  }

  return (
    <>
      <HelmetComponent title={t('ΕΑΤΑ') || undefined} descrition={t('ΕΑΤΑ') || undefined} />






      <div className={classes.imageContainer}>

        <div className={classes.imageWelcome}>
          {/* <img src={imageWelcome} alt="Welcome Image" /> */}
          <div className={classes.imageWelcomeTitle}>
          </div>
          <div className={classes.imageWelcomeDesc}>
            <span>{t('ΣΧΕΤΙΚΑ ΜΕ ΤΗΝ ΕΑΤΑ')}</span>
          </div>

        </div>
      </div>




      <div className={classes.container}>
        <div className={classes.faqContainer}>
          <span className={classes.eataText}>
            Η Εταιρεία Ανάπτυξης και Τουριστικής Προβολής του Δήμου Αθηναίων (ΕΑΤΑ), σχεδιάζει και υλοποιεί αναπτυξιακές και προωθητικές δράσεις για την ενίσχυση της εικόνας της πόλης. Πρωταρχικός της ρόλος είναι η Αθήνα να καταστεί ιδανικός προορισμός για κατοίκους, επισκέπτες, επαγγελματίες και επενδυτές.
          </span>
          <br /><br />
          <span className={classes.eataText}>
            H προώθηση της καινοτομίας, της ανταγωνιστικότητας και της επιχειρηματικότητας -με έμφαση στους τομείς του Τουρισμού και του Πολιτισμού- η έξυπνη και βιώσιμη διαχείριση του αστικού περιβάλλοντος της Αθήνας, όπως και η προώθηση της κοινωνικής συνοχής και ένταξης είναι τομείς στους οποίους εστιάζει η Εταιρεία μέσα από τα προγράμματά της.
          </span>
          <br /><br />
          <span className={classes.eataText}>
            Η ΕΑΤΑ υποστηρίζει τις στρατηγικές και επενδυτικές προτεραιότητες του Δήμου Αθηναίων, εξασφαλίζοντας τους πόρους χρηματοδότησης μέσα από ευρωπαϊκά προγράμματα. Παράλληλα, συμπράττει με φορείς του ιδιωτικού τομέα προκειμένου να σχεδιάσουν και να υλοποιήσουν από κοινού προγράμματα και έργα που συμβάλλουν στην κοινωνική, επιχειρηματική και αστική ανάπτυξη.
          </span>
          <br /><br />
          <span className={classes.eataText}>
            Στόχος των προγραμμάτων είναι η ενίσχυση της οικονομίας των επισκεπτών της πόλης με έργα που αφορούν στην ανάπτυξη της Αθήνας μέσω της αναβάθμισης του δημόσιου χώρου και των υπηρεσιών της για κατοίκους και επισκέπτες, η προβολή και προώθηση της πόλης στο εξωτερικό ως αυτόνομου προορισμού, 12 μήνες τον χρόνο, καθώς και η διαχείριση του προορισμού με την υποδοχή των επισκεπτών και τη δημιουργία ενιαίας εμπειρίας τους.
          </span>
          <br /><br />
          <span className={classes.eataText}>
            Οι επενδύσεις και η ενίσχυση της επιχειρηματικής δραστηριότητας στην Αθήνα, συγκαταλέγονται στις προτεραιότητες της ΕΑΤΑ που μέσα από κίνητρα και επενδυτικές ευκαιρίες επιδιώκει την τόνωση των τοπικών αγορών, αλλά και την ανάδειξη της πόλης σε διεθνή επενδυτικό προορισμό.
          </span>
          <br /><br />
          <span className={classes.eataText}>
            Στο πλαίσιο της διαχείρισης της κοινωνικής κρίσης, η Εταιρεία αναπτύσσει δράσεις και προγράμματα με στόχο την αντιμετώπιση φαινομένων κοινωνικού αποκλεισμού και τη διασφάλιση της κοινωνικής συνοχής.
          </span>
          <br /><br />
          <span className={classes.eataText}>
            Ως Ενδιάμεσος Φορέας Διαχείρισης και μέσω των πόρων που της εκχωρήθηκαν από το Επιχειρησιακό Πρόγραμμα «Αττική», η ΕΑΤΑ υλοποιεί παρεμβάσεις που αφορούν σε δράσεις αστικής ανάπλασης, προστασίας του περιβάλλοντος, βελτίωσης της ενεργειακής απόδοσης, προάσπισης της υγείας, κοινωνικής πρόνοιας και εκπαίδευσης, καθώς και δράσεις πολιτιστικής και τουριστικής ανάπτυξης και στήριξης της επιχειρηματικότητας, με αποδέκτες τους πολίτες, τους επισκέπτες και τους επιχειρηματίες της πόλης της Αθήνας.
          </span>
          <br /><br />
          <span className={classes.eataText}>
            Η ΕΑΤΑ εκτελεί επίσης χρέη <strong>Τελικού Δικαιούχου</strong> ως φορέας πρότασης ή/και υλοποίησης έργων που χρηματοδοτούνται από <strong>προγράμματα της ΕΕ</strong> (HORIZON, URBACT, INTERREG, DG ECHO, UIA, EASY κ.ά.), για την υλοποίηση του αναπτυξιακού προγράμματος του Δήμου, και την ενισχύει τη <strong>διεθνή δικτύωση</strong> του Δήμου Αθηναίων μέσα από τη συμμετοχή σε διεθνή δίκτυα.
          </span>
          <br /><br />
          <span className={classes.eataText}>
            Επίσης, υλοποιεί <strong>προγραμματικές συμφωνίες</strong> με τον Δήμο Αθηναίων για την υποστήριξη των υπηρεσιών του και την υλοποίηση δράσεων που απαιτούν εξειδικευμένο προσωπικό. Ενδεικτικά αναφέρονται οι ακόλουθες προγραμματικές συμβάσεις:
          </span>
          <br /><br />
          <span className={classes.eataText}>
          
            <ul style={{listStyleType: 'disc'}}>
              <li>
                Προγραμματική <strong>Σεραφείου</strong> (ολοκληρώθηκε τον Ιούνιο του 2020), με αντικείμενο την υλοποίηση ενεργειών και δράσεων προώθησης του Ευ Ζην και της βελτιστοποίησης της ποιότητας ζωής στο πλαίσιο της δια βίου μάθησης, της διατροφής, αλλά και της καινοτομίας και της στήριξης της επιχειρηματικότητας. Στόχος της προγραμματικής σύμβασης υπήρξε ειδικότερα η ανάπτυξη του Σεραφείου ως κόμβος καινοτομίας της Αθήνας, ο οποίος παρέχει στους νέους τα εφόδια για να εξελιχθούν μέσα από δυναμικές συνεργασίες – πραγματικές εφαρμογές στο πεδίο, εκπαίδευση και διασυνδέσεις. Η ΕΑΤΑ διαχειρίστηκε επίσης τη φιλοξενία στους χώρους του Σεραφείου των λειτουργιών του Start Project, της δημοτικής τεχνολογικής θερμοκοιτίδας Athens Digital Lab, του Makers Space, του Athens Film Office, του Urban Innovative Actions και του This is Athens.
              </li>
              <br />
              <li>
                Προγραμματική <strong>ΣΟΑΠ Γ’ Φάση</strong>, με αντικείμενο την υλοποίηση καινοτόμων παρεμβάσεων στον Δήμο Αθηναίων, οι οποίες παρουσιάζουν άμεση συνέργεια με αντίστοιχες δράσεις του Σχεδίου Ολοκληρωμένης Αστικής Παρέμβασης (ΣΟΑΠ): Αναβάθμιση επιλεγμένων πλατειών και κοινοχρήστων χώρων, Προώθηση κοινωνικής ένταξης μέσω δράσεων βελτίωσης των ευκαιριών απασχόλησης ευάλωτων ομάδων πληθυσμού και μέσω δράσεων που αφορούν τις υποδομές υγείας, Ενέργειες δια βίου μάθησης και Ψηφιακή Αθήνα.
              </li>
              <br />
              <li>
                Προγραμματική <strong>Προσέλκυση Επενδύσεων – Invest in Athens</strong>, που εστιάζει στην προβολή της πόλης ως επενδυτικό προορισμό, και ειδικότερα στην αξιοποίηση του κτιριακού αποθέματος του Δήμου Αθηναίων με σκοπό την ανάπτυξη της επιχειρηματικότητας. Στο πλαίσιο αυτό πραγματοποιεί καταγραφή της αναξιοποίητης ακίνητης περιουσίας του Δήμου σε αστικές περιοχές με εξαιρετική δυναμική και περιθώριο τουριστικής, πολιτιστικής και επιχειρηματικής ανάπτυξης, και επιδιώκει την παροχή κινήτρων για την αξιοποίησή της.
              </li>
              <br />
              <li>
                Προγραμματική <strong>Τεχνική Υποστήριξη</strong>, με αντικείμενο τη σύμπραξη του Δήμου Αθηναίων με την ΕΑΤΑ ΑΕ για την εκτέλεση εξειδικευμένων ενεργειών και δράσεων για την εφαρμογή, την υλοποίηση και την παρακολούθηση των αναπτυξιακών προγραμμάτων του Δήμου (ΟΧΕ, Τετραετές Επιχειρησιακό Πρόγραμμα).
              </li>
            </ul>
            </span>
          <br /><br />
          <span className={classes.eataText}>
            Σημαντικό πεδίο δραστηριοποίησης της ΕΑΤΑ είναι επίσης η λειτουργία της ως <strong>οργανισμός διαχείρισης προορισμού (DMO)</strong>, για την για την επίτευξη του στόχου της προώθησης και προβολής της Αθήνας ως τουριστικού και επιχειρηματικού προορισμού. Στο πλαίσιο αυτό η σχετική δραστηριότητα της ΕΑΤΑ αναπτύσσεται μέσω δυναμικών εργαλείων, πρωτοποριακών δράσεων και στρατηγικών συμμαχιών με τον ιδιωτικό τομέα. Ενδεικτικά αναφέρονται η λειτουργία του “This is Athens”, του γραφείου Συνεδρίων & Επισκεπτών του Δήμου Αθηναίων, του “This is Athens & Partners” και του “Athens Film Office”.
          </span>
        </div>
        <br />
        <div className={classes.socialMedia}>
          <img src={facebook} alt="Facebook icon" />
          <img src={twitter} alt="Twitter icon" />
          <img src={linkedIn} alt="LinkedIn icon" />
        </div>
      </div >
    </>
  )
}

export default About
